<template>
  <div class="HistoryListPage">
    <div class="Nav">
      <div class="NavList" v-for="(value, key) in navList.list" :key="key">
        <span
            @click="changeNav($event, key)"
            ref="navList"
            :class="{ active: navList.nowSelectedNo == key }"
        >{{ value }}</span>
      </div>
      <div
          class="NavBottomLine"
          id="navBottomLine"
          :style="navBottomLine"
      ></div>
    </div>
    <div class="historyList">
      <!-- 列表 -->
      <div class="list">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="van-pull-refresh">
          <van-list
              class="dataList"
              v-model="loading"
              :finished="finished"
              @load="onLoad"
              v-if="busList.length > 0"
              offset="300"
          >
            <div
                class="itemBox"
                v-for="(value, key) in busList"
                :key="key"
            >
              <div class="dataItem" :class="isVipStyle(value.vip)">
                <p class="vipTag" v-if="value.vip"> <span>出行+ 会员券 </span> </p>
              <div class="dataItemBox">
                <!--              优惠券信息-->
                <div :class="{ opacity: status==2 ||status==4 }">
<!--                  <img-->
<!--                      class="icon"-->
<!--                      v-bind:src="value.icon">-->
                  <div class="divCenter">
                    <P>{{ value.couponName }}</P>
                    <p>{{ value.validDate }}-{{ value.invalidDate }}</p>
                    <p v-if="kind == 40 && value.durationLimit">使用时间段：{{value.durationLimit}}</p>
                  </div>
                  <div class="divRight">
                    <span class="moneySpan">
                      <span class="money1" v-if="value.type!=30">￥</span>
                      <span class="money2">{{ formatDiscount(value.type, value.discount) }}</span>
                      <span class="money1" v-if="value.type==30">折</span>
                    </span>
<!--                    <p class="p1" v-if="value.vip">会员专享</p>-->
                    <p class="p2" v-show="value.type==10">{{ fullTip(value.full) }}</p>
                  </div>
                </div>
                <!--              虚线-->
                <div>
                  <div class="leftCircle">
                  </div>
                  <van-divider dashed class="dashLine"
                               :style="{  borderColor: '#0000001A' }">
                  </van-divider>
<!--                  <div class="dashLine"></div>-->
                  <div class="rightCircle">

                  </div>
                </div>
                <!--                  使用规则-->
                <div>
                  <div class="top">
                    <div class="openRule" @click="checkDescription($event, key)">
                      <span class="spanRule" :class="{ opacity: status==2 ||status==4 }">使用规则</span>
                      <img class="arrowIcon opacity" v-if="listItem.includes(key)"
                           src="../../assets/icon_up_arrow.png">
                      <img class="arrowIcon opacity" v-if="!listItem.includes(key)"
                           src="../../assets/icon_down_arrow.png">
                    </div>
                    <button class="button" :class="isVipBtn(value.vip)" v-if="status==2 && (kind!=40 && kind!=50) " @click="buttonOnClick(value.recordNo)">查看账单</button>
                  </div>
                  <p class="bottom opacity" :class="{ show: listItem.includes(key)  }">{{ value.description }}</p>
                </div>
              </div>
              </div>
            </div>
          </van-list>
          <!-- 没有数据 -->
          <div class="noData" v-else>
            <img
                src="../../assets/icon_no_coupon.png"
                alt=""
            />
            <p>无优惠券</p>
          </div>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>
import {personalCoupon} from "../../api/HttpService";

export default {
  name: "HistoryListPage",
  created: function () {
    setTimeout(() => {
      this.navBottomLine.left = this.$refs.navList[0].offsetLeft + "px";
      this.navBottomLine.width = this.$refs.navList[0].offsetWidth + "px";
    }, 0);
  },
  mounted: function () {
    document.title = "历史记录";
    if (this.$route.params.kind){
      this.kind = this.$route.params.kind.toString();
    }
    if (window.localStorage.getItem("historyKind")) {
      this.kind = window.localStorage.getItem("historyKind")
    }
    this.personalCoupon()
  },
  data: function () {
    return {
      navList: {
        nowSelectedNo: 0,
        list: ["已使用", "已过期"],
      },
      navBottomLine: {
        left: "0px",
        width: "0px",
      },
      loading: false,
      //   公交
      busList: [],
      refreshing: false,
      finished: false,
      pageNum: 1,
      pageSize: 8,
      total: 0,
      kind: "10",
      status: 2,
      descriptionShow: false,
      listItem: [],

    };
  },
  methods: {
    //   点击顶部nav
    changeNav: async function ($event, key) {
      if (this.navList.nowSelectedNo == key) {
        // 如果key 没有变化，那么什么都不做
      } else {
        // 否则
        switch (key) {
          case 0:
            this.status = 2;
            break;
          case 1:
            this.status = 4;
            break;
        }
        this.navList.nowSelectedNo = key;
        this.busList = [];
        this.listItem = [];
        this.pageNum = 1;
        this.finished = false;
        this.navBottomLine.left = $event.target.offsetLeft + "px";
        this.navBottomLine.width = $event.target.offsetWidth + "px";
        await this.personalCoupon();
      }
    },
    checkDescription: async function ($event, key) {
      if (this.listItem.includes(key)) {
        var item = this.listItem.indexOf(key)
        console.log(event.target +key+ "splice" + item)
        this.listItem.splice(item, 1)
      } else {
        this.listItem.push(key);
      }
    },
    // 获取数据
    personalCoupon: async function () {
      let params = {
        pageNum: this.pageNum,
        pageSize: 10,
        kind: this.kind,
        status: this.status,
        classes: 10,
      };
      await personalCoupon(params)
          .then(({res}) => {
            this.loading = false;
            // 如果没有下一页了
            if (res.data.pageNum >= res.data.pages) {
              console.log("是否还有下一页")
              this.finished = true;
            } else {
              this.finished = false;
            }
            this.busList.push(...res.data.data)
            this.pageNum++;
          })
          .catch((err) => {
            console.log(err);
            // this.$toast("网络异常");
          });
    },
    onLoad: async function () {
      console.log("加载下一页");
      await this.personalCoupon();
    },
    // list刷新
    onRefresh: async function () {
      console.log("开始刷新");
      this.busList = [];
      this.pageNum = 1;
      this.finished = false;
      this.refreshing = true;
      await this.personalCoupon();
      this.refreshing = false;
    },
    //右侧button的点击事件
    buttonOnClick: function (recordNo) {
      window.localStorage.setItem("historyKind",this.kind)
      switch (this.kind) {
        case "10"://公交订单
          var _url =
              window.location.host.match(/test/) == null
                  ? "https://h5-lzgj.brightcns.com/dxlcbill/#/BillDetailPage?couponNo="+recordNo
                  : "https://lzgj.test.brightcns.cn/dxlcbill/#/BillDetailPage?couponNo="+recordNo;
          console.log(window.location.host+_url)
          window.location.href = _url;
          break
        case "20"://定制公交订单
          window.location.href = "liangla://msg/cstmbusOrder?couponNo="+recordNo;
          break
        case "30"://国联行
          var url =
              window.location.host.match(/test/) == null
                  ? "https://h5-lzgj.brightcns.com/glx/#/"
                  : "https://lzgj.test.brightcns.cn/glx/#/";
          window.location.href = url;
          break
      }
    },
    //折扣赋值转换
    formatDiscount: function (type, discount) {
      switch (type) {
        case 30:
          return this.formatMoney(discount * 10);
        default:
          return discount;
      }
    },
    //格式化
    formatMoney: function (money) {
      var value = Math.round(parseFloat(money) * 100) / 100;
      var xsd = value.toString().split(".");
      if (xsd.length == 1) {
        value = value.toString() + ".0";
        return value;
      }
      if (xsd.length > 1) {
        if (xsd[1].length < 1) {
          value = value.toString() + "0";
        }
        return value;
      }
      return value;
    },

    fullTip: function(full){
      return "满"+full/100+"元可用"
    },

    isVipStyle:function (isVip) {
      if (isVip){
        return "vipBg"
      }
    },

    isVipBtn:function (isVip) {
      if (isVip){
        return "vipBtn"
      }
      return "Btn"
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../common/scss/coupon.scss";
@import "../../page/HistoryListPage/HistoryListPage.scss";
</style>
